import React from 'react';
import App from 'next/app';
import bugsnagClient from '~/util/bugsnag';
import Error from '~/pages/_error';
import { MIGRATED_ROUTES } from '~/v2/app/api/routes/migrated/route';

declare var BRANCH_KEY: string;

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

export default class MyApp extends App {
  async componentDidMount() {
    const BranchSDK = (await import('branch-sdk')).default;
    BranchSDK.init(BRANCH_KEY);

    BranchSDK.data(err => {
      if (err) {
        console.warn(`Branch failed to resolve link: ${err}`);
      }
    });
  }

  render() {
    const { Component, pageProps } = this.props;

    const { asPath } = this.props.router;

    const isPageMigrated = !!MIGRATED_ROUTES.find(route => !!asPath.match(route));

    if (isPageMigrated && typeof window !== 'undefined') {
      if (process.env.NODE_ENV === 'production') {
        window.location.reload();
      } else {
        window.location.href = `http://localhost:3010/${asPath}`;
      }

      return <></>;
    }

    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }
}
