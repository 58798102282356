export const MIGRATED_ROUTES = ['/pga/events/*', '/lpga/events/*'];

export const POST = async (req: Request) => {
  const requestUrl = await req.text();

  const isMigrated = MIGRATED_ROUTES.find(r => new RegExp(r).test(requestUrl));

  console.log(`MIGRATED [${requestUrl}] => ${isMigrated ? 'true' : 'false'}`);

  return new Response(null, {
    status: isMigrated ? 200 : 404
  });
}